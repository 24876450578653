<template>
  <div class="app-container sub-device-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createApp">添加应用</el-button>
        <el-button plain icon="el-icon-refresh-right" @click="findAppPage"
          >刷新</el-button
        >
      </span>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <!-- 添加驱动对话框 -->
    <el-dialog
      :visible.sync="driver.addDialogVisible"
      :before-close="cancelDeviceSave"
      width="728px"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ driver.title }}
      </template>
      <el-form
        label-position="top"
        :model="driver.form"
        ref="deviceForm"
        :rules="driver.formRules"
      >
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.appName')" prop="appDriverName">
              <el-input
                :disabled="this.currentState === 'edit'"
                v-model="driver.form.appDriverName"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.language')" prop="appLanguage">
              <el-input
                v-model="driver.form.appLanguage"
                :disabled="this.currentState === 'edit'"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item
              :label="$t('vlink.tsl.description')"
              prop="description"
            >
              <el-input
                v-model="driver.form.description"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.log.version')" prop="version">
              <el-input
                v-model="driver.form.version"
                :disabled="this.currentState === 'edit'"
                maxlength="32"
                :show-word-limit="true"
                clearable
                :placeholder="$t('commons.pleaseInput')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item :label="$t('vlink.app.appType')" prop="appType">
              <el-select
                :style="{ width: '100%' }"
                filterable
                clearable
                v-model="driver.form.appType"
                :disabled="this.currentState === 'edit'"
              >
                <el-option
                  v-for="item in appTypeOptions"
                  :key="item.Value"
                  :label="item.label"
                  :value="item.Value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.device.protocolType')" prop="owner">
              <el-select
                :style="{ width: '100%' }"
                filterable
                clearable
                v-model="driver.form.owner"
                :disabled="this.currentState === 'edit'"
              >
                <el-option
                  v-for="item in options"
                  :key="item.typeValue"
                  :label="item.label"
                  :value="item.typeValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelDeviceSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleDeviceSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  findApp,
  deleteApp,
  createApp,
  editApp,
} from "@/api/ruge/vlink/app/app";
export default {
  name: "appManager",
  components: {
    finalTable,
  },
  data() {
    return {
      currentState: null,
      options: [
        {
          typeValue: "private",
          label: "私有",
        },
        {
          typeValue: "authority",
          label: "官方",
        },
      ],
      appTypeOptions: [
        {
          Value: "协议驱动",
          label: "协议驱动",
        },
        {
          Value: "边缘应用",
          label: "边缘应用",
        },
      ],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        header: [
          {
            prop: "appDriverName",
            label: this.$t("vlink.app.appName"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "appLanguage",
            label: this.$t("vlink.app.language"),
            width: "",
          },
          {
            prop: "owner",
            label: this.$t("vlink.device.protocolType"),
            width: "",
          },
          {
            prop: "version",
            label: this.$t("vlink.log.version"),
            width: "",
          },
          {
            prop: "appType",
            label: this.$t("vlink.app.appType"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "140",
          },
        ],
        tableData: [],
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          owner: {
            type: "tags",
            private: {
              type: "private",
              label: "私有",
            },
            authority: {
              type: "authority",
              label: "官方",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "detail",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "详情",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {
          appDriverName: {
            inline: true,
            value: "",
          },
          description: {
            inline: true,
            value: "",
          },
          app: {
            inline: true,
            value: "",
          },
          version: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      driver: {
        title: null,
        total: 0,
        list: null,
        listLoading: false,
        addDialogVisible: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          tenantId: null,
        },
        form: {
          appId: null,
          owner: null,
          appType: null,
          appDriverName: null,
          appLanguage: null,
          version: null,
          description: null,
        },
        formRules: {
          appDriverName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          owner: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
          appType: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
          ],
        },
      },
    };
  },
  created() {
    this.findAppPage();
  },
  methods: {
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.findAppPage();
      } else if (datas.type === "paginationChange") {
        this.driver.listQuery.current = datas.params.current.page;
        this.driver.listQuery.rowCount = datas.params.current.limit;
        this.findApp();
      } else if (datas.type === "updateSelectionList") {
        this.device.multipleSelection = datas.list;
      } else if (datas.type === "switchEvent") {
        this.updateEnableStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editApp(datas.row);
            break;
          case "delete":
            this.$confirm(
              this.$t("message.deleteConfirm"),
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                this.deleteApp(datas.row.appId);
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });
            break;
          case "detail":
            this.goDetailHandler(datas.row);
            break;
        }
      }
    },
    goDetailHandler(row) {
       let appId = row.appId;
       let appDriverName = row.appDriverName;
      let query = {
        appId,appDriverName
      };
      this.$router.push({
        path: "/iot/appManage/detail",
        query,
      });
    },
    // 取消保存
    cancelDeviceSave() {
      this.driver.addDialogVisible = false;
    },
    createApp() {
      this.currentState = "add";
      this.driver.title = this.$t("vlink.app.addApp");
      this.driver.form = {
        appDriverName: null,
        driverLanguage: null,
        version: null,
        description: null,
        owner: null,
        appType: null,
      };
      this.driver.addDialogVisible = true;
    },

    handleDeviceSave() {
      this.$refs.deviceForm.validate((v) => {
        if (v) {
          if (this.driver.form.appId) {
            editApp(this.driver.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelDeviceSave();
                this.findAppPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          } else {
            createApp(this.driver.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.cancelDeviceSave();
                this.findAppPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              });
          }
        }
      });
    },
    findAppPage() {
      this.driver.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.findApp();
    },
    findApp() {
      let param = this.driver.listQuery;
      findApp(param)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch((err) => {
          console.log("错误信息：", err);
        });
    },
    // 删除
    deleteApp(appId) {
      deleteApp({ appId: appId })
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.deleteSuccess"),
            type: "success",
          });
          this.findAppPage();
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 修改
    editApp(row) {
      this.currentState = "edit";
      this.driver.title = this.$t("vlink.app.editApp");
      this.driver.form.owner = row.owner;
      this.driver.form.appDriverName = row.appDriverName;
      this.driver.form.appType = row.appType;
      this.driver.form.description = row.description;
      this.driver.form.appLanguage = row.appLanguage;
      this.driver.form.version = row.version;
      this.driver.form.appId = row.appId;
      this.driver.addDialogVisible = true;
    },
  },
};
</script>
<style scoped lang="less">
.sub-device-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>