import request from '@/utils/request';
import {
    envInfo
} from '@/constants/envInfo';

/**
 * 查询应用列表
 * @param params
 */
export function findApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/search/appPage',
        method: 'get',
        params
    });
}

/**
 * 删除应用
 * @param params
 */
export function deleteApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/deleteApp/' + params.appId,
        method: 'delete',
    });
}

/**
 * 创建应用
 * @param params
 */
export function createApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/createApp',
        method: 'post',
        data: params,
    });
}

/**
 * 创建应用
 * @param params
 */
export function editApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/updateApp',
        method: 'post',
        data: params,
    });
}

/**
 * 查询绑定列表
 * @param params
 */
export function findBindApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/search/bindApp',
        method: 'get',
        params,
    });
}

/**
 * 网关绑定驱动
 * @param params
 */
export function bindApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/deviceBindApp/',
        method: 'post',
        data: params,
    });
}

/**
 * 删除设备绑定应用
 * @param params
 */
export function deleteBindApp(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/deleteBindApp/' + params.bindId,
        method: 'delete',
    });
}

/**
 * 查询应用镜像列表
 * @param params
 */
export function findAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/search/appImagePage',
        method: 'get',
        params
    });
}

/**
 * 创建应用镜像
 * @param params
 */
export function createAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/create/appImage',
        method: 'post',
        data: params,
    });
}
/**
 * 修改应用镜像
 * @param params
 */
export function editAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/update/appImage',
        method: 'post',
        data: params,
    });
}

/**
 * 删除应用镜像
 * @param params
 */
export function deleteAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/delete/appImage/' + params.appImageId,
        method: 'delete',
    });
}

/**
 * 发布应用镜像
 * @param params
 */
export function publishAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/pushApp',
        method: 'post',
        data: params,
    });
}

/**
 * 绑定应用到网关
 * @param params
 */
export function bindApp2Edge(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/use/bindEdge',
        method: 'post',
        data: params,
    });
}

/**
 * 发布应用镜像
 * @param params
 */
export function useAppImage(params) {
    return request({
        url: envInfo.bgApp.appPath + '/info/use/appImage',
        method: 'post',
        data: params,
    });
}